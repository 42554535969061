import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import {
    BpeService,
    EmailScenario,
    EmailTemplate,
    ScenarioAttributeItem,
    ScenarioItem,
    ToDoTemplate,
} from "../../../bpe/bpe.service";
import { MultiselectModelI } from "../../../s25-multiselect/s25.multiselect.component";
import { S25Util } from "../../../../util/s25-util";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { SearchCriteriaType } from "../../../../pojo/SearchCriteriaI";
import { S25ItemI } from "../../../../pojo/S25ItemI";
import { ModalService } from "../../../modal/modal.service";
import { Bind } from "../../../../decorators/bind.decorator";
import { MultiselectResultsApi } from "../../../s25-multiselect/s25.multiselect.results.component";
import { BpeUtil } from "../../../bpe/bpe.util";
import { S25LoadingApi } from "../../../s25-loading/loading.api";
import { CustomAttributes } from "../../../../pojo/CustomAttributes";

@TypeManagerDecorator("s25-ng-email-scenario")
@Component({
    selector: "s25-ng-email-scenario",
    template: `
        <div *ngIf="isInit">
            <div class="scenarioRow">
                <label for="item-name" class="ngBold">Scenario Name: </label>
                <input [(ngModel)]="scenario.itemName" id="item-name" name="item-name" class="c-input" type="text" />
            </div>

            <div class="scenarioRow">
                <label for="active" class="ngBold">Active: </label>
                <s25-toggle-button [(modelValue)]="scenario.isActive" id="active" name="active"></s25-toggle-button>
            </div>

            <div class="scenarioRow">
                <label for="active" class="ngBold">Scheduled: </label>
                <s25-toggle-button
                    [(modelValue)]="scenario.isScheduled"
                    id="scheduled"
                    name="scheduled"
                ></s25-toggle-button>
            </div>

            <div *ngIf="scenario.isScheduled" class="scenarioRow">
                <label class="ngBold"
                    >Schedule Type:
                    <select [(ngModel)]="scenario.scheduleType" class="cn-form__control">
                        <option value="daysFromEventStart">Days from Event Start</option>
                        <option value="daysFromEventEnd">Days from Event End</option>
                    </select>
                </label>

                <label class="ngBold offsetLabel"
                    >Schedule Offset Days:
                    <input type="number" class="c-input" [(ngModel)]="scenario.scheduleDays" />
                </label>
            </div>

            <label class="ngBold">Scenario Instructions</label>
            <s25-ng-info-message [open]="true">
                When an event is saved or tasks page is triggered, 25Live checks the criteria below. A criteria matches
                if at least one of its "Include Any", and none of its "Include None" values match. If all criteria
                match, then email(s) and/or To Do task(s) will generate based on the selected templates. Be sure to
                preview your scenario before saving.
            </s25-ng-info-message>

            <div class="scenarioRow">
                <label for="toDo" class="ngBold">Create To Do: </label>
                <s25-toggle-button [(modelValue)]="scenario.createToDo" id="toDo" name="toDo"></s25-toggle-button>
            </div>

            <ng-container *ngIf="scenario.mode === 'hybridForm'">
                <label class="ngBold">Trigger Actions On:</label>
                <fieldset class="radios">
                    <s25-ng-radio [(modelValue)]="scenario.codeData.on" [name]="'sendEmailOn'" [value]="'create'"
                        >Event Creation</s25-ng-radio
                    >
                    <s25-ng-radio [(modelValue)]="scenario.codeData.on" [name]="'sendEmailOn'" [value]="'edit'"
                        >Event Edit</s25-ng-radio
                    >
                    <s25-ng-radio [(modelValue)]="scenario.codeData.on" [name]="'sendEmailOn'" [value]="'all'"
                        >Both</s25-ng-radio
                    >
                </fieldset>

                <label class="ngBold">Trigger Template Actions When Saving From:</label>
                <div *ngIf="scenario.isActive && !hasChosenSource" class="ngRed">
                    Scenario will be triggered on all of the below
                </div>
                <fieldset class="d-flex flex-column">
                    <s25-ng-checkbox
                        [(modelValue)]="scenario.codeData.sources['event-form']"
                        (modelValueChange)="setHasChosenSource()"
                    >
                        Event Form
                    </s25-ng-checkbox>
                    <s25-ng-checkbox
                        [(modelValue)]="scenario.codeData.sources['event-state-change']"
                        (modelValueChange)="setHasChosenSource()"
                    >
                        Event State Dropdown
                    </s25-ng-checkbox>
                    <s25-ng-checkbox
                        [(modelValue)]="scenario.codeData.sources.express"
                        (modelValueChange)="setHasChosenSource()"
                    >
                        Express Scheduling
                    </s25-ng-checkbox>
                    <s25-ng-checkbox
                        [(modelValue)]="scenario.codeData.sources.task"
                        (modelValueChange)="setHasChosenSource()"
                    >
                        Tasks Page
                    </s25-ng-checkbox>
                    <s25-ng-checkbox
                        [(modelValue)]="scenario.codeData.sources['cancelTodos']"
                        (modelValueChange)="setHasChosenSource()"
                    >
                        Cancel Request
                    </s25-ng-checkbox>
                </fieldset>

                <div class="d-flex flex-column">
                    <h3 class="ngBold c-margin-top--single">Criteria</h3>

                    <ng-container
                        [ngTemplateOutlet]="criteriaListTemplate"
                        [ngTemplateOutletContext]="{ key: 'post' }"
                    ></ng-container>

                    <h3 class="ngBold c-margin-top--single">Pre Criteria</h3>

                    <ng-container
                        [ngTemplateOutlet]="criteriaListTemplate"
                        [ngTemplateOutletContext]="{ key: 'pre' }"
                    ></ng-container>

                    <ng-template #criteriaListTemplate let-key="key">
                        <ng-container *ngFor="let criterion of criteriaList">
                            <s25-simple-collapse
                                *ngIf="!!criteria[key][criterion]"
                                [defaultCollapsed]="true"
                                [headerText]="criteria[key][criterion].label + getCountLabel(criteria[key][criterion])"
                            >
                                <div class="collapseContent">
                                    <div *ngIf="criterion === 'locations'" class="scenarioRow includePref">
                                        <label>Include Location Preferences: </label>
                                        <s25-toggle-button
                                            [(modelValue)]="scenario.codeData[key].usePrefLocations"
                                        ></s25-toggle-button>
                                    </div>
                                    <div *ngIf="criterion === 'resources'" class="scenarioRow includePref">
                                        <label>Include Resource Preferences: </label>
                                        <s25-toggle-button
                                            [(modelValue)]="scenario.codeData[key].usePrefResources"
                                        ></s25-toggle-button>
                                    </div>

                                    <div class="includeExclude">
                                        <div class="include">
                                            <h3>Include Any</h3>
                                            <ng-container
                                                [ngTemplateOutlet]="criterionTemplate"
                                                [ngTemplateOutletContext]="{ type: 'include' }"
                                            ></ng-container>
                                        </div>

                                        <div class="exclude">
                                            <h3>Include None</h3>
                                            <ng-container
                                                [ngTemplateOutlet]="criterionTemplate"
                                                [ngTemplateOutletContext]="{ type: 'exclude' }"
                                            ></ng-container>
                                        </div>
                                    </div>

                                    <ng-template #criterionTemplate let-type="type">
                                        <s25-ng-multiselect-search-criteria
                                            [modelBean]="criteria[key][criterion][type].multiselect"
                                            [selectedItems]="criteria[key][criterion][type].chosen"
                                            [type]="criteria[key][criterion].type"
                                            [customFilterValue]="
                                                criteria[key][criterion][type].multiselect.customFilterValue
                                            "
                                            [popoverOnBody]="true"
                                            [attr.type]="criteria[key][criterion].type"
                                        ></s25-ng-multiselect-search-criteria>

                                        <div *ngIf="criterion === 'customAttributes'">
                                            <div
                                                *ngFor="
                                                    let attribute of criteria[key][criterion][type].multiselect
                                                        .selectedItems
                                                "
                                                class="customAttribute"
                                            >
                                                <div class="scenarioRow">
                                                    <label class="ngBold">{{ attribute.itemName }}</label>
                                                    <button class="closeButton">
                                                        <s25-ng-icon
                                                            [type]="'close'"
                                                            (click)="
                                                                criteria[key][criterion][
                                                                    type
                                                                ].multiselect.removeSelectedItem(attribute)
                                                            "
                                                        ></s25-ng-icon>
                                                    </button>
                                                </div>
                                                <s25-ng-custom-attribute-value
                                                    [attributeId]="$any(attribute.itemId)"
                                                    [attributeType]="attribute.itemTypeId"
                                                    [(operator)]="attribute.operator"
                                                    [(value)]="attribute.itemValue"
                                                ></s25-ng-custom-attribute-value>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                            </s25-simple-collapse>
                        </ng-container>
                        <s25-simple-collapse
                            *ngIf="key === 'post'"
                            [defaultCollapsed]="true"
                            [headerText]="
                                'Tasks (' +
                                (+!!scenario.codeData.taskTriggers.assignments +
                                    !!scenario.codeData.taskTriggers.approvals +
                                    !!scenario.codeData.taskTriggers.fyis) +
                                ')'
                            "
                        >
                            <fieldset class="d-flex flex-column task-margin-left">
                                <s25-ng-checkbox [(modelValue)]="scenario.codeData.taskTriggers.assignments">
                                    All Assignment Policy Complete</s25-ng-checkbox
                                >
                                <s25-ng-checkbox [(modelValue)]="scenario.codeData.taskTriggers.approvals">
                                    All Notification Policy Approvals Complete
                                </s25-ng-checkbox>
                                <s25-ng-checkbox [(modelValue)]="scenario.codeData.taskTriggers.fyis">
                                    All Notification Policy FYIs Complete
                                </s25-ng-checkbox>
                            </fieldset>
                        </s25-simple-collapse>
                    </ng-template>
                </div>
            </ng-container>

            <ng-container *ngIf="scenario.mode === 'hybridCode'">
                <h3 class="ngBold c-margin-top--single">Code</h3>
                <textarea [(ngModel)]="scenario.code" rows="20" cols="120" class="cn-form__control code"></textarea>

                <s25-simple-collapse
                    [defaultCollapsed]="true"
                    [titleText]="'Code Variables'"
                    [headerText]="'Code Variables'"
                >
                    <div class="c-evddWrapper">
                        <bpe-vars [codeMode]="true"></bpe-vars>
                    </div>
                </s25-simple-collapse>
            </ng-container>

            <h3 class="ngBold c-margin-top--single">Templates</h3>
            <s25-ng-multiselect-search-criteria
                [type]="'emailTemplates'"
                [modelBean]="templates"
                [selectedItems]="templates.selectedItems"
                [popoverOnBody]="true"
                [popoverPlacement]="'right'"
            ></s25-ng-multiselect-search-criteria>
            <button class="aw-button aw-button--outline" (click)="onCreateTemplate(0)">
                Create New Email Template
            </button>
            <span *ngIf="scenario.createToDo">
                <h3 class="ngBold c-margin-top--single">To Do Templates</h3>
                <s25-ng-multiselect-search-criteria
                    [type]="'todoTemplates'"
                    [modelBean]="todoTemplates"
                    [selectedItems]="todoTemplates.selectedItems"
                    [popoverOnBody]="true"
                    [popoverPlacement]="'right'"
                ></s25-ng-multiselect-search-criteria>
                <button class="aw-button aw-button--outline" (click)="onCreateTemplate(1)">
                    Create New To Do Template
                </button>
            </span>

            <h3 class="ngBold c-margin-top--single">Advanced</h3>
            <button class="aw-button aw-button--outline d-block" (click)="toggleView()">
                {{ scenario.mode === "hybridForm" ? "Code" : "Form" }} View
            </button>

            <h3 class="ngBold c-margin-top--single">Preview by Reference</h3>
            <input
                [(ngModel)]="eventReference"
                id="preview-reference"
                name="preview-reference"
                class="c-input"
                placeholder="YYYY-ABCDEF"
            />
            <button class="aw-button aw-button--primary" (click)="showPreview()">Preview</button>

            <s25-loading-inline [model]="{}"></s25-loading-inline>
            <div class="buttons c-margin-top--single">
                <button class="aw-button aw-button--primary" (click)="onSave()">Save</button>
                <button class="aw-button aw-button--outline" (click)="onCancel()">Cancel</button>
            </div>
        </div>
    `,
    styles: `
        .scenarioRow {
            display: flex;
            gap: 1em;
            align-items: center;
            margin: 0.5em 0;
        }

        .offsetLabel,
        .scenarioRow > label:first-child {
            min-width: 10em;
            margin: 0;
        }

        .scenarioRow > input {
            min-width: 15em;
        }

        #preview-reference {
            margin-right: 0.5em;
        }

        .radios {
            display: flex;
            gap: 0.5em;
            align-items: center;
        }

        label {
            margin-top: 1em;
        }

        .buttons {
            display: flex;
            gap: 0.5em;
        }

        ::ng-deep .s25-multiselect-popup-container {
            max-width: 50vw;
        }

        .collapseContent {
            padding: 1em;
        }

        .customAttribute {
            border-top: 1px solid rgba(0, 0, 0, 0.25);
        }

        .customAttribute:last-child {
            border-bottom: 1px solid rgba(0, 0, 0, 0.25);
        }

        s25-ng-custom-attribute-value {
            display: block;
            text-align: left;
        }

        s25-ng-multiselect-search-criteria {
            margin-top: 1em;
            display: block;
        }

        ::ng-deep .s25-multiselect-popup .s25-multiselect-columns-container {
            max-height: 35vh;
        }

        s25-ng-info-message {
            margin-left: 0.5em;
        }

        .task-margin-left {
            margin: 20px;
        }

        .code {
            white-space: pre;
            font-family: monospace;
            font-size: 14px;
            min-width: 100%;
        }

        .includeExclude {
            display: flex;
        }

        ::ng-deep .nm-party--on s25-ng-email-scenario .includeExclude {
            color: white;
        }

        .includeExclude > div {
            width: 50%;
        }

        .includeExclude .include {
            padding-right: 1em;
        }

        .includeExclude .exclude {
            border-left: 1px solid rgba(0, 0, 0, 0.15);
            padding-left: 1em;
        }

        .includeExclude h3 {
            text-align: center;
            font-weight: bold;
        }

        .includeExclude s25-ng-multiselect-search-criteria {
            text-align: center;
        }

        .includePref {
            margin: 0 auto 1em auto;
            width: fit-content;
        }

        s25-simple-collapse {
            margin-top: -1.25rem;
        }

        .closeButton {
            border: 0;
            background: transparent;
            color: #6c6c6c;
            padding: 0;
            cursor: pointer;
            border-radius: 50%;
            height: 2em;
            width: 2em;
            transition: all 0.2s;
        }

        .closeButton:hover {
            background: #fafaf9;
            color: var(--color-primary) !important;
        }

        .closeButton:focus {
            box-shadow: 0 0 0 2px var(--color-primary);
            color: var(--color-primary) !important;
            outline: 0;
        }

        .closeButton:active {
            background: #f0f1f2;
        }

        .customAttribute .scenarioRow {
            justify-content: space-between;
        }

        s25-ng-multiselect-search-criteria[type$="CustomAttributes"] {
            margin-bottom: 1em;
        }
    `,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25EmailScenarioComponent implements OnInit {
    @Input() scenario: EmailScenario;
    @Input() eventReference: string;

    @Output() saved = new EventEmitter<void>();
    @Output() cancelled = new EventEmitter<void>();

    isInit = false;
    criteriaList: ScenarioItemTypeName[] = [
        "states",
        "types",
        "locations",
        "resources",
        "primaryOrgs",
        "requirements",
        "securityGroups",
        "customAttributes",
    ];
    criteria: {
        pre: Record<ScenarioItemTypeName, IncludeExclude>;
        post: Record<ScenarioItemTypeName, IncludeExclude>;
    };
    criteriaDefaults = {
        states: { label: "Event States", type: "eventStates", hasPre: true },
        types: { label: "Event Types", type: "eventTypes", hasPre: true },
        locations: { label: "Locations", type: "locations", hasPre: true },
        resources: { label: "Resources", type: "resources", hasPre: true },
        primaryOrgs: { label: "Primary Organizations", type: "organizations", hasPre: true },
        requirements: { label: "Requirements", type: "eventRequirements", hasPre: true },
        securityGroups: { label: "Security Groups", type: "securityGroups", hasPre: false },
        customAttributes: { label: "Custom Attributes", type: "eventCustomAttributes", hasPre: true },
    };
    customAttributes = new Map<number, CustomAttribute>();
    templates: MultiselectModelI;
    todoTemplates: MultiselectModelI;
    hasChosenSource: boolean = true;

    constructor(
        private elementRef: ElementRef,
        private changeDetector: ChangeDetectorRef,
    ) {}

    async ngOnInit() {
        this.scenario = S25Util.deepCopy(this.scenario); // Copy to avoid modifying original

        this.templates = {
            showMatching: false,
            showResult: true,
            selectedItems: this.scenario.templates as S25ItemI[],
            title: "Templates",
            uuid: "templates-uuid",
        };

        this.todoTemplates = {
            showMatching: false,
            showResult: true,
            selectedItems: this.scenario.todoTemplates as S25ItemI[],
            title: "To Do Templates",
            uuid: "todoTemplates-uuid",
        };

        this.scenario = BpeUtil.scenarioGetData(this.scenario);
        this.criteria = this.getCriteria(this.scenario);
        this.setHasChosenSource();
        this.isInit = true;
        this.changeDetector.detectChanges();
    }

    getCriteria(scenario: EmailScenario) {
        const criteria: any = { pre: {}, post: {} };

        for (let key of ["pre", "post"] as ("pre" | "post")[]) {
            for (let criterion of this.criteriaList) {
                if (key === "pre" && !this.criteriaDefaults[criterion].hasPre) continue;
                criteria[key][criterion] = {
                    ...this.criteriaDefaults[criterion],
                    include: this.getCriterionState(scenario, criterion, key, "include"),
                    exclude: this.getCriterionState(scenario, criterion, key, "exclude"),
                };
            }
        }

        return criteria;
    }

    getCriterionState(
        scenario: EmailScenario,
        criterion: ScenarioItemTypeName,
        key: "pre" | "post",
        type: "include" | "exclude",
    ) {
        const state: any = {
            chosen: scenario.codeData[key]?.[criterion]?.[type] || [],
            multiselect: {
                showMatching: false,
                showResult: criterion !== "customAttributes",
                items: [],
                selectedItems: [],
                values: [],
            },
        };

        const allowedCustomAttributeTypes = new Set(["S", "X", "E", "R", "D", "T", "N", "F", "B", 2, 4, 6]);

        if (criterion === "requirements") state.multiselect.customFilterValue = "all_types=F";
        if (criterion === "customAttributes") {
            state.multiselect.extractItems = (items: any) => {
                return items.filter((item: any) => allowedCustomAttributeTypes.has(item.itemTypeId));
            };
            const before = S25Util.deepCopy(state.chosen);
            state.chosen = state.chosen.map((item: ScenarioAttributeItem) => ({
                ...item,
                itemTypeId: item.custAtrbType,
                itemValue:
                    typeof item.custAtrbType === "number"
                        ? { itemName: item.additionalInfo, itemId: item.itemValue }
                        : item.itemValue,
            }));
        }

        return state;
    }

    onCreateTemplate(id: any) {
        if (id === 1) {
            const template: Omit<ToDoTemplate, "itemId"> = {
                mode: "form",
                itemName: "New To Do  Template",
                dueDate: 0,
                code: "",
                assignTo: "",
                assignBy: "",
                taskName: "",
                comment: "",
                isToDoTemplate: id,
            };
            ModalService.modal("edit-email-template", {
                template,
                title: `Create New To Do Template`,
                onSave: this.onToDoTemplateSave,
            });
        } else {
            const template: Omit<EmailTemplate, "itemId"> = {
                comment: "",
                mode: "form",
                cc: "",
                reports: "",
                itemName: "New Email Template",
                bcc: "",
                code: "",
                subject: "",
                isManual: 0,
                iCalFile: 0,
                to: "",
                body: "",
                isToDoTemplate: id,
            };
            ModalService.modal("edit-email-template", {
                template,
                title: `Create New Email Template`,
                onSave: this.onTemplateSave,
            });
        }
    }

    @Bind
    async onTemplateSave(data: { id: number }) {
        const templates = await BpeService.getFullTemplates();
        const template = templates.find((item) => item.itemId === data.id);
        if (!template) return alert("Something went wrong creating template");
        this.templates.items = templates as S25ItemI[];
        this.templates.selectedItems.push(template as S25ItemI);
        MultiselectResultsApi.refresh(this.elementRef.nativeElement, "templates-uuid");
        this.changeDetector.detectChanges();
    }

    @Bind
    async onToDoTemplateSave(data: { id: number }) {
        const templates = await BpeService.getFullTodoTemplates();
        const template = templates.find((item) => item.itemId === data.id);
        if (!template) return alert("Something went wrong creating template");
        this.todoTemplates.items = templates as S25ItemI[];
        this.todoTemplates.selectedItems.push(template as S25ItemI);
        MultiselectResultsApi.refresh(this.elementRef.nativeElement, "todoTemplates-uuid");
        this.changeDetector.detectChanges();
    }

    async toggleView() {
        if (this.scenario.mode === "hybridForm") {
            // Need to convert form data into code
            this.gatherMultiselectData();
            this.scenario = {
                ...this.scenario,
                mode: "hybridCode",
                code: BpeUtil.hybridScenarioToCode(this.scenario),
            };
        } else {
            // Warn user that changes may be lost
            let dialogData = ModalService.dialogType(
                "Yes No",
                {
                    message:
                        "Changes made to code may be lost when switching to Form View. Are you sure that you want to switch?",
                    title: "Switch to Form View",
                },
                "No",
            );
            await ModalService.modal("dialog", dialogData);
            if (dialogData.answer !== 1) return; // User answered no

            // If we started with legacy code mode, use legacy form data
            if (this.scenario.mode === "code") this.scenario = BpeUtil.legacyScenarioFormToHybrid(this.scenario);
            else {
                this.scenario.mode = "hybridForm";
                this.scenario.codeData = BpeUtil.parseCode(this.scenario.code);
            }
            this.criteria = this.getCriteria(this.scenario);
        }
        this.changeDetector.detectChanges();
    }

    showPreview() {
        if (this.scenario.mode === "hybridForm") this.gatherMultiselectData();
        const code = BpeUtil.scenarioToPreviewCode(this.scenario);
        const templates = this.templates.selectedItems as any as EmailTemplate[];
        ModalService.modal("email-scenario-preview", {
            templates: templates.map((template) => ({
                name: template.itemName,
                code: `{${template.mode === "form" ? BpeUtil.templateToCode(template) : template.code}}`,
            })),
            name: this.scenario.itemName,
            code: `{${code}}`,
            reference: this.eventReference,
        });
    }

    gatherMultiselectData() {
        // Criteria
        const mapItem = (item: ScenarioItem) => ({ itemId: item.itemId as number, itemName: item.itemName });
        const mapCustomAttributeItem = (item: ScenarioAttributeItem & { itemTypeId: number }) => {
            const { itemId, itemName, itemTypeId, itemValue, operator } = item;
            return {
                itemId,
                itemName,
                itemValue: itemValue?.itemId ?? itemValue,
                additionalInfo: itemValue?.itemName,
                custAtrbType: itemTypeId,
                operator: operator,
            } as ScenarioAttributeItem;
        };
        for (let key of Object.keys(this.criteria) as ("pre" | "post")[]) {
            for (let criterion of Object.keys(this.criteria[key]) as ScenarioItemTypeName[]) {
                for (let type of ["include", "exclude"] as const) {
                    if (!this.criteria?.[key]?.[criterion]?.[type]) continue;
                    const selectedItems = this.criteria[key][criterion][type].multiselect.selectedItems;
                    this.scenario.codeData[key][criterion][type] = selectedItems.map(
                        criterion === "customAttributes" ? mapCustomAttributeItem : mapItem,
                    );
                }
            }
        }

        // Templates
        this.scenario.templates = this.templates.selectedItems.map((item) => {
            const { mode, cc, itemId, reports, itemName, bcc, code, subject, isManual, iCalFile, to, body, from } =
                item;
            return {
                mode,
                cc,
                itemId,
                reports,
                itemName,
                bcc,
                code,
                subject,
                isManual,
                iCalFile,
                to,
                body,
                from,
            } as EmailTemplate;
        });
        if (this.todoTemplates && this.todoTemplates.selectedItems) {
            // To Do Templates
            this.scenario.todoTemplates = this.todoTemplates.selectedItems.map((item) => {
                const { mode, itemId, itemName, taskName, code, comment, dueDate, assignTo, assignBy } = item;
                return {
                    mode,
                    itemId,
                    taskName,
                    itemName,
                    comment,
                    code,
                    dueDate,
                    assignTo,
                    assignBy,
                } as ToDoTemplate;
            });
        }
    }

    async onSave() {
        this.gatherMultiselectData();
        if (!this.scenario.itemName) return alert("A scenario name is required");

        if (this.scenario.createToDo && !this.scenario.todoTemplates?.length)
            return alert("Please select a To Do template");

        if (this.scenario.isScheduled && !this.scenario.scheduleType)
            return alert("When scheduled, a schedule type is required");

        if (this.scenario.isScheduled && S25Util.isUndefined(this.scenario.scheduleDays))
            return alert("When scheduled, schedule offset days are required");

        const attributes = this.scenario.codeData.pre.customAttributes.include.concat(
            this.scenario.codeData.pre.customAttributes.exclude,
            this.scenario.codeData.post.customAttributes.include,
            this.scenario.codeData.post.customAttributes.exclude,
        );
        for (let attribute of attributes) {
            if (!attribute.operator)
                return alert(`Please select operator for custom attribute "${attribute.itemName}"`);
            if (attribute.itemValue === undefined)
                return alert(`Please select value for custom attribute "${attribute.itemName}"`);
        }

        const payload = {
            itemId: this.scenario.itemId,
            itemName: this.scenario.itemName,
            mode: this.scenario.mode,
            code: BpeUtil.hybridScenarioToCode(this.scenario),
            isActive: this.scenario.isActive ? 1 : 0,
            createToDo: this.scenario.createToDo ? 1 : 0,
            templates: this.scenario.templates,
            todoTemplates: this.scenario.todoTemplates,
            isScheduled: this.scenario.isScheduled ? 1 : 0,
            scheduleType: this.scenario.isScheduled ? this.scenario.scheduleType : undefined,
            scheduleDays: this.scenario.isScheduled ? this.scenario.scheduleDays : undefined,
        };

        S25LoadingApi.init(this.elementRef.nativeElement);
        const ok = await BpeService.putScenarioByScenario(payload as EmailScenario).catch(this.error);
        S25LoadingApi.destroy(this.elementRef.nativeElement);
        if (ok) this.saved.emit();
    }

    onCancel() {
        this.cancelled.emit();
    }

    error(error: any) {
        S25Util.showError(error);
    }

    getCountLabel(data: IncludeExclude) {
        return ` (${data.include.chosen.length + data.exclude.chosen.length})`;
    }

    removeAttribute(multiselect: any, item: any) {
        multiselect.removeSelectedItem(item);
    }

    //sets hasChosenSource to true if any of the sources are true.
    setHasChosenSource() {
        this.hasChosenSource = Object.values(this.scenario.codeData.sources).some((value) => value === true);
        this.changeDetector.detectChanges();
    }
}

export type ScenarioItemTypeName =
    | "states"
    | "types"
    | "locations"
    | "resources"
    | "primaryOrgs"
    | "requirements"
    | "securityGroups"
    | "customAttributes";

type IncludeExclude = {
    label: string;
    type: string;
    hasPre: boolean;
    include: CriterionData;
    exclude: CriterionData;
};

type CriterionData = {
    chosen: ScenarioItem[];
    multiselect: MultiselectModelI;
    label: string;
    type: SearchCriteriaType["type"];
};

type CustomAttribute = {
    type: CustomAttributes.Type;
    itemId: number;
    itemName: string;
};
