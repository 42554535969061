import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import { ToDoTemplate } from "../../../bpe/bpe.service";
import { S25Util } from "../../../../util/s25-util";
import { TypeManagerDecorator } from "../../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-todo-template")
@Component({
    selector: "s25-ng-todo-template",
    template: `
        <div *ngIf="isInit">
            <div class="templateRow">
                <label for="item-name" class="ngBold">Template Name: </label>
                <input
                    [(ngModel)]="template.itemName"
                    id="itemName"
                    (change)="onChange()"
                    name="itemName"
                    class="c-input"
                    type="text"
                    maxlength="40"
                />
            </div>

            <div *ngIf="template.mode === 'form'">
                <label class="ngBold">Template Instructions</label>
                <s25-ng-info-message [open]="true">
                    <span>You may use an option below for the Assigned By and Assigned To fields:</span>
                    <ul>
                        <li>Specific contacts chosen via Select Contacts</li>
                        <li>The contact ID variable for an event role (found under Template Variables).</li>
                        <li>
                            The "Assigned To" user will need at least Edit rights on the event in order for the To Do
                            task to be assigned to them.
                        </li>
                        <!-- <li>Event role name, such as 'requestor' or 'scheduler'</li> -->
                    </ul>
                </s25-ng-info-message>

                <div class="templateRow">
                    <label for="taskName" class="ngBold">Task Name </label>
                    <input
                        type="text"
                        [(ngModel)]="template.taskName"
                        (change)="onChange('taskName')"
                        id="taskName"
                        name="taskName"
                        class="c-input"
                        maxlength="80"
                    />
                </div>

                <div class="templateRow">
                    <label for="assignedBy" class="ngBold">Assigned By: </label>
                    <input
                        type="text"
                        [(ngModel)]="template.assignBy"
                        (change)="onChange('by')"
                        id="assignBy"
                        name="assignBy"
                        class="c-input"
                    />

                    <s25-ng-multiselect-search-criteria
                        [type]="'contacts'"
                        [selectedItems]="multiSelectBeanAssignBy.selectedItems"
                        [modelBean]="multiSelectBeanAssignBy"
                        [popoverOnBody]="true"
                        [popoverPlacement]="'bottom-left top-left top left'"
                    ></s25-ng-multiselect-search-criteria>
                </div>

                <div class="templateRow">
                    <label for="assignedTo" class="ngBold">Assigned To: </label>
                    <input
                        type="text"
                        [(ngModel)]="template.assignTo"
                        (change)="onChange('to')"
                        id="assignTo"
                        name="assignTo"
                        class="c-input"
                    />
                    <s25-ng-multiselect-search-criteria
                        [type]="'contacts'"
                        [selectedItems]="multiSelectBeanAssignTo.selectedItems"
                        [modelBean]="multiSelectBeanAssignTo"
                        [popoverOnBody]="true"
                        [popoverPlacement]="'bottom-left top-left top left'"
                    ></s25-ng-multiselect-search-criteria>
                </div>

                <div class="templateRow">
                    <label for="dueDate" class="ngBold">Due Date: </label>
                    <input
                        type="number"
                        [(ngModel)]="template.dueDate"
                        (change)="onChange()"
                        id="dueDate"
                        name="dueDate"
                        class="c-input"
                    />
                    <div class="small">(+/- days from the first occurrence)</div>
                </div>

                <div>
                    <label for="comment" class="ngBold c-margin-top--half">Comment: </label>
                    <div>
                        <textArea rows="4" cols="50" (change)="this.onTextChange($event.target.value)">
                            {{ template.comment }}</textArea
                        >
                    </div>
                </div>
                <s25-loading-inline [model]="{}"></s25-loading-inline>
            </div>
        </div>
    `,
    styles: `
        .templateRow {
            display: flex;
            gap: 1em;
            align-items: center;
            margin: 0.5em 0;
        }

        .templateRow > label:first-child {
            min-width: 10em;
            margin: 0;
        }

        .templateRow > input {
            min-width: 15em;
        }

        .reports {
            display: flex;
            justify-content: space-between;
            width: 15em;
        }

        .buttons {
            display: flex;
            gap: 0.5em;
        }

        ::ng-deep s25-ng-email-template s25-ng-rich-text-editor .tox-tinymce {
            width: 100% !important;
        }

        s25-ng-info-message {
            margin-left: 0.5em;
        }

        ::ng-deep .s25-multiselect-popup-container {
            max-width: 40vw;
            max-height: 80vh;
        }

        ::ng-deep .s25-multiselect-popup .s25-multiselect-columns-container {
            max-width: 50vw;
            max-height: 35vh;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25ToDoTemplateComponent implements OnInit {
    @Input() template: ToDoTemplate;
    @Input() eventReference: string = "";

    @Output() saved = new EventEmitter<{ id: number }>();
    @Output() cancelled = new EventEmitter<void>();
    @Output() modelValueChange = new EventEmitter();

    isInit = false;
    showFrom = false;

    multiSelectBeanAssignTo: any;
    multiSelectBeanAssignBy: any;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {}

    ngOnInit() {
        this.template = S25Util.deepCopy(this.template); // Copy to avoid changing original

        this.multiSelectBeanAssignTo = {
            singleSelect: true,
            buttonText: "Select Contact",
            //showResult: true,
            action: this.editAssignedTo,
        };
        this.multiSelectBeanAssignTo.selectedItems = [];
        if (this.template.assignTo && Number.isInteger(this.template.assignTo)) {
            this.multiSelectBeanAssignTo.selectedItems = [{ itemId: this.template.assignTo }];
        }

        this.multiSelectBeanAssignBy = {
            singleSelect: true,
            buttonText: "Select Contact",
            // showResult: true,
            action: this.editAssignedBy,
        };
        this.multiSelectBeanAssignBy.selectedItems = [];
        if (this.template.assignBy && Number.isInteger(this.template.assignBy)) {
            this.multiSelectBeanAssignBy.selectedItems = [{ itemId: this.template.assignBy }];
        }

        this.isInit = true;
        this.changeDetector.detectChanges();
    }

    onChange(e?: any) {
        if (e && e === "by") {
            this.template.assignBy !== "" && this.template.assignBy.indexOf("{{$pro.vars.roleContactId") === -1
                ? alert("Please enter a valid contact or Event Role Variable for the Assigned By field.")
                : "";
            this.template.multiSelectBeanAssignBy && delete this.template.multiSelectBeanAssignBy;
        } else if (e && e === "to") {
            this.template.assignTo !== "" && this.template.assignTo.indexOf("{{$pro.vars.roleContactId") === -1
                ? alert("Please enter a valid contact or Event Role Variable for the Assigned To field.")
                : "";
            this.template.multiSelectBeanAssignTo && delete this.template.multiSelectBeanAssignTo;
        }

        if (
            e &&
            e === "taskName" &&
            this.template.taskName.indexOf("{{$pro.vars.") === -1 &&
            this.template.taskName.length > 40
        ) {
            alert("Task name must be 40 characters or less.");
        }

        if (e && e === "taskName" && this.template.taskName.indexOf("{{$pro.vars.") > -1) {
            alert(
                "The task name field is limited to 40 characters. If using a variable in this field, ensure it is 40 characters or less so it is not unexpectedly cut off.",
            );
        }

        if (!Number.isInteger(this.template.dueDate)) {
            alert("Please enter a valid number for Due Date field.");
        }
        this.modelValueChange.emit(this.template);
    }

    onTextChange(e: any) {
        this.template.comment = e;
        this.modelValueChange.emit(this.template);
    }

    editAssignedTo = () => {
        const selected = this.multiSelectBeanAssignTo.selectedItems?.[0];
        if (!selected || this.template.assignTo === parseInt(selected.itemId)) return;
        this.template.assignTo = selected.itemName;
        this.template.multiSelectBeanAssignTo = this.multiSelectBeanAssignTo;
        this.onChange();
    };

    editAssignedBy = () => {
        const selected = this.multiSelectBeanAssignBy.selectedItems?.[0];
        if (!selected || this.template.assignBy === parseInt(selected.itemId)) return;
        this.template.assignBy = selected.itemName;
        this.template.multiSelectBeanAssignBy = this.multiSelectBeanAssignBy;
        this.onChange();
    };

    error(error: any) {
        S25Util.showError(error);
    }
}
